import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getArticleBySlug } from "../../utils/articleService";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import MarkdownRenderer from "../MarkdownRenderer";
import AdBlock from "../adblock/Adblock";

function BlogPost() {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      const article = await getArticleBySlug(slug);
      setArticle(article);
    };
    fetchArticle();
  }, [slug]);

  if (!article) {
    return <div>Ładowanie...</div>;
  }

  return (
    <div>
      <Header />
      <AdBlock />
      <div className="container mx-auto px-4 py-8">
        <nav className="mb-4">
          <Link to="/blog" className="text-blue-500 hover:underline">
            &larr; Powrót do artykułów
          </Link>
        </nav>
        <h1 className="text-3xl font-bold mb-4">{article.title}</h1>
        <p className="text-gray-600 mb-4">{article.date}</p>
        <img
          src={article.image}
          alt={article.title}
          className="w-full h-[32rem] object-cover mb-4" // Increased height
        />
        <MarkdownRenderer content={article.content} />
      </div>
      <Footer />
    </div>
  );
}

export default BlogPost;
