import { remark } from "remark";
import html from "remark-html";
import frontMatter from "front-matter";
import removeMarkdown from "remove-markdown";

const articlesContext = require.context("../articles", false, /\.md$/);

export const getArticles = async () => {
  const articles = await Promise.all(
    articlesContext
      .keys()
      .sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0], 10);
        const numB = parseInt(b.match(/\d+/)[0], 10);
        return numB - numA; // Change the order to descending
      })
      .map(async (filename) => {
        const fileContent = await fetch(articlesContext(filename)).then((res) =>
          res.text()
        );
        const { attributes: data, body: content } = frontMatter(fileContent);
        const result = await remark().use(html).process(content);
        const plainTextContent = removeMarkdown(content);
        const description =
          plainTextContent.length > 200
            ? plainTextContent.slice(0, 200) + "..."
            : plainTextContent;
        return {
          ...data,
          description, // Use the calculated description
          slug: filename.replace(/\.\/|\.md$/g, ""),
          content: result.toString(),
        };
      })
  );
  return articles;
};

export const getArticleBySlug = async (slug) => {
  const filename = `./${slug}.md`;
  const fileContent = await fetch(articlesContext(filename)).then((res) =>
    res.text()
  );
  const { attributes: data, body: content } = frontMatter(fileContent);
  const result = await remark().use(html).process(content);
  return {
    ...data,
    content: result.toString(),
  };
};
