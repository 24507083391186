import React from "react";

function Footer() {
  return (
    <footer class="h-72 sm:h-64 p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
      <div class="mx-auto max-w-screen-xl text-center">
        <a
          href="/"
          class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img src="/logo.webp" alt="3D Promo" class="w-32 mr-2" />
        </a>
        <p class="my-6 text-gray-600 dark:text-gray-300">
          Agregator promocji na akcesoria do druku 3D oraz drukarki
        </p>
        <p class="my-4 text-gray-500 dark:text-gray-400 text-sm">
          Jestem Partnerem Amazon i otrzymuję wynagrodzenie z tytułu określonych
          zakupów dokonywanych za moim pośrednictwem.
        </p>

        <span class="text-sm text-gray-600 sm:text-center dark:text-gray-300">
          2025{" "}
        </span>
      </div>
    </footer>
  );
}

export default Footer;
