import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage"; // Import Home component
import BlogPage from "./pages/BlogPage"; // Import BlogPage component
import BlogPost from "./components/blog/BlogPost";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        {/* ...other routes... */}
      </Routes>
    </Router>
  );
}

export default App;
