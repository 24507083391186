import React, { useEffect, useState } from "react";
import { getArticles } from "../../utils/articleService";
import { Link } from "react-router-dom";

function Blog() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articles = await getArticles();
      setArticles(articles);
    };
    fetchArticles();
  }, []);

  const handleImageLoad = (e) => {
    e.target.style.opacity = "1";
    e.target.previousSibling.style.opacity = "0";
  };

  const handleImageError = (e) => {
    e.target.style.opacity = "0";
    e.target.previousSibling.style.opacity = "1";
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map((article) => (
          <Link
            key={article.slug}
            to={`/blog/${article.slug}`}
            className="bg-white rounded-lg shadow-md overflow-hidden block"
          >
            <div className="relative w-full h-64 bg-gray-300">
              <div className="absolute inset-0 w-full h-full bg-gray-300"></div>
              <img
                src={article.image}
                alt={article.title}
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ opacity: "0", transition: "opacity 0.5s ease-in-out" }}
              />
            </div>
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">{article.title}</h2>
              <p className="text-gray-600 mb-2">{article.date}</p>
              <p className="text-gray-700 mb-4">{article.description}</p>
              <span className="text-blue-500 hover:underline">
                Zobacz więcej
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Blog;
