import React from "react";
import { useDetectAdBlock } from "adblock-detect-react";

const AdBlock = () => {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="flex justify-center">
      {adBlockDetected && (
        <div className="border border-gray-300 p-4 text-sm rounded-md">
          Ta strona jest wolna od reklam. Będzie nam miło jeśli wyłączysz
          blokera reklam. Dzięki temu otrzymamy prowizję za zakup. Dziękujemy.
        </div>
      )}
    </div>
  );
};

export default AdBlock;
