function TableItem(props) {
  function urlWithTrackingTag(url) {
    const amazonTrackingId = "3dpromo02e-21";
    return url + `?tag=${amazonTrackingId}`;
  }
  return (
    <tr
      className={
        props.i % 2 === 0
          ? "bg-slate-100 hover:bg-slate-200"
          : "hover:bg-slate-200"
      }
      key={props.i}
    >
      <td className="h-24 w-24 sm:h-48 sm:w-48 p-4 border-b border-blue-gray-50">
        <div className="flex justify-center items-center gap-3 h-24 w-24 sm:h-48 sm:w-48">
          <img
            // className="h-36 w-44 sm:w-16"
            src={props.item.imageUrl}
            alt=""
            loading="lazy"
          />
        </div>
      </td>
      <td className="max-w-32 s:max-w-46 p-2 border-b border-blue-gray-50">
        <div className="flex items-center gap-3">
          <div className="flex flex-col">
            <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
              <a
                href={urlWithTrackingTag(props.item.productUrl)}
                target="_blank"
                rel="noreferrer"
                class="w-32 s:w-46 h-4"
                data-umami-event="Open product"
                data-umami-event-url={props.item.productUrl}
              >
                {" "}
                {props.item.name}
              </a>
            </p>
          </div>
        </div>
      </td>
      <td className="p-4 border-b border-blue-gray-50">
        <div className="flex flex-col">
          <p className="w-16 h-4 block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
            {props.item.price} {props.item.currency}
          </p>
        </div>
      </td>
    </tr>
  );
}

export default TableItem;
