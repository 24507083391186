import React from "react";
import Header from "../components/header/Header";
import Blog from "../components/blog/Blog";
import Footer from "../components/footer/Footer";
import AdBlock from "../components/adblock/Adblock";

function BlogPage() {
  return (
    <div>
      <Header />
      <AdBlock />
      <Blog />
      <Footer />
    </div>
  );
}

export default BlogPage;
