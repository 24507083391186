import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();

  return (
    <header className="h-16">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="w-16 h-6 sm:w-24 sm:h-9 flex items-center">
            <img
              src="/logo.webp"
              className="mr-3 w-16 h-6 sm:w-24 sm:h-9"
              alt="3D Promo Logo"
            />
            <span className="w-96 self-center text-base font-semibold whitespace-nowrap dark:text-white">
              Twój portfel nas pokocha
            </span>
          </a>
          <div
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <Link
                  to="/"
                  className={`block py-2 pr-4 pl-3 rounded ${
                    location.pathname === "/"
                      ? "text-white bg-primary-700 lg:bg-transparent lg:text-primary-700"
                      : "text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  }`}
                  aria-current={location.pathname === "/" ? "page" : undefined}
                >
                  Strona główna
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className={`block py-2 pr-4 pl-3 rounded ${
                    location.pathname.startsWith("/blog")
                      ? "text-white bg-primary-700 lg:bg-transparent lg:text-primary-700"
                      : "text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  }`}
                  aria-current={
                    location.pathname.startsWith("/blog") ? "page" : undefined
                  }
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
