import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";

const MarkdownRenderer = ({ content }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeHighlight]}
      components={{
        h1: ({ node, children, ...props }) => (
          <h1 className="text-4xl font-bold my-4" {...props}>
            {children}
          </h1>
        ),
        h2: ({ node, children, ...props }) => (
          <h2 className="text-3xl font-semibold my-3" {...props}>
            {children}
          </h2>
        ),
        h3: ({ node, children, ...props }) => (
          <h3 className="text-2xl font-medium my-2" {...props}>
            {children}
          </h3>
        ),
        p: ({ node, children, ...props }) => (
          <p className="text-base my-2" {...props}>
            {children}
          </p>
        ),
        ul: ({ node, children, ...props }) => (
          <ul className="list-disc list-inside my-2" {...props}>
            {children}
          </ul>
        ),
        ol: ({ node, children, ...props }) => (
          <ol className="list-decimal list-inside my-2" {...props}>
            {children}
          </ol>
        ),
        li: ({ node, children, ...props }) => (
          <li className="my-1" {...props}>
            {children}
          </li>
        ),
        em: ({ node, children, ...props }) => (
          <em className="italic" {...props}>
            {children}
          </em>
        ),
        strong: ({ node, children, ...props }) => (
          <strong className="font-bold" {...props}>
            {children}
          </strong>
        ),
        a: ({ node, children, ...props }) => (
          <a className="text-blue-500 hover:underline" {...props}>
            {children}
          </a>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
