import React from "react";
import Header from "../components/header/Header";
import Table from "../components/table/Table";
import Footer from "../components/footer/Footer";
import AdBlock from "../components/adblock/Adblock";

const HomePage = () => {
  return (
    <>
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <AdBlock />
        <Table />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
